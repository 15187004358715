.toast {
    &.bg-success {
        .toast-body {
            color: #fff;
        }
    }

    .toast-body {
        color: #fff;
    }
}

.toast-container {
    position: fixed !important;
    width: 100% !important;
    max-width: 400px !important;
}