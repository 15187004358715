:root {
    --background-color: #ffffff; 
}

#root {
    background-color: var(--background-color);
    min-height: 100vh;
}

* {
    box-sizing: border-box;
}

.img-wrap {
    position: relative;

    img {
        position: relative;
        top: -16px;
        left: -16px;
    }
    
   &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: var(--secondary-color);
        border-radius: 16px;
    }
}

.iframe-embed-container {
    background: #f3eee2;
}