.notifications-container {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .notification-bar {
    padding: 10px 20px;
    text-align: center;
    transition: opacity 0.5s ease;
  }
  
  .notification-content {
    display: inline-block;
  }
  
  .notification-content h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .notification-content p {
    margin: 0;
    font-size: 14px;
  }
  
  .notification-content a {
    display: inline-block;
    margin-top: 5px;
    text-decoration: underline;
  }
  