button {
    pointer-events: all;
    cursor: pointer;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    transition: all .3s ease;
}

.btn-primary {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #000;
    font-weight: bold;

    &:hover, &:focus, &:active, &:disabled {
        background: #f0909b;
        border-color: #f0909b;
        color: #000;
    }
}

.btn-secondary {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #000;
    font-weight: bold;


    &:hover, &:focus, &:active, &:disabled {
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        color: #000;
    }
}

.btn-icon-invert img {
    filter: invert(1) brightness(2);
}

.btn-move {
    padding: 6px !important;
    background: #ccc !important;

    &:disabled {
        opacity: 0.3 !important;
    }
}

.btn-move-down {
    img {
        transform: rotate(180deg);
    }
}

.btn-circle {
    border-radius: 50%;
}


.btn-close-widget {
    margin-top: -16px;
    margin-left: -8px;
    opacity: 0;
    transition: all 0.5s ease;
}

.resizable-draggable-container {
    &:hover .btn-close-widget {
        opacity: 1;
    }
}