:root {
    --background-color: #ffffff;
    --primary-color: #feecba;
    --secondary-color: #98d2dc;
    --body-font-family: 'Arial, sans-serif';
    --bs-primary: '#0d6efd';
    --bs-secondary: '#6c757d';
    --bs-btn-bg: #feecba;
    --bs-btn-border-color: #feecba;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #feecba;
    --bs-btn-hover-border-color: #feecba;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    // --bs-btn-active-bg: #f0909b;
    --bs-btn-active-bg: #feecba;
    --bs-btn-active-border-color: #feecba;
  }
  
  body.govcms {
    background-color: var(--background-color);
    color: var(--primary-color);
    font-family: var(--body-font-family);
  }


