/* Fade-in effect for the initial load */
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  /* Optional: You can also add a slide-in effect */
  .slide-in {
    transform: translateY(0);
    opacity: 0;
    animation: slideIn 0.5s forwards;
  }
  
  @keyframes slideIn {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  